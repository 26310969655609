import React from 'react';
import { createRoot } from 'react-dom/client';
import {IndexApp} from './IndexApp';
import './index.css';
import 'bootstrap/dist/css/bootstrap.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
// Put any other imports below so that CSS from your
// components takes precedence over default styles.

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

// library.add(fab, faCheckSquare, faCoffee);
library.add(fab, fas, far);

root.render(
  <React.StrictMode>
    <IndexApp />
  </React.StrictMode>
);
// ReactDOM.render(
//   <homeApp />,
//   document.getElementById('root')
// );
