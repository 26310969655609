import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Totalticket = ({ ticket }) => {

    const formatterSoles = new Intl.NumberFormat('es-PE', { style: 'currency', currency: 'PEN' });

    var porcentajeVal = ticket.porcentaje.substring(0, 1);
    var elem = document.getElementById('idPorcentajeticket');
    var elem1 = document.getElementById('iconPorcticket');
    if (elem != null) {
        if (porcentajeVal == '-') {
            elem.style.color = '#ff4d4d';
            elem1.style.backgroundColor = '#ff4d4d';
        } else {
            elem.style.color = '#58C347';
            elem1.style.backgroundColor = '#58C347';
        }
    }

    return (
        <>
        
        <div className="col">
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-auto">
                                <FontAwesomeIcon icon={ 'fa-solid fa-ticket' } 
                                size='2x' color='#E38C7F'/>
                        </div>
                        <div className="col valortotalpasado2 d-flex align-items-center">
                            Ticket promedio
                        </div>
                    </div>
                    <div className="totalIndicador">
                        { formatterSoles.format(ticket.total) }
                    </div>
                    <div className="row">
                        <div className="col-auto">
                            <div className="valortotalpasado">{ ticket.label }</div>
                            <div className="valortotalpasado2">
                                { formatterSoles.format(ticket.totalAyer) }
                            </div>
                        </div>
                        <div className="col align-items-center">
                            <div className="row">
                                <div id="idPorcentajeticket" 
                                className="fw-bolder fs-4 d-flex justify-content-end col">
                                    { ticket.porcentaje }
                                </div>
                                <div className="col-auto rounded d-flex align-items-center"
                                id='iconPorcticket'>
                                    <FontAwesomeIcon icon={ ticket.icon } 
                                    color='white'/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        </>
    )
}