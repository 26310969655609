import React, { useState } from 'react'
import { getFacturas } from '../helpers/getFacturas';

export const SelectTienda = ({setTienda, tiempo, setIcons, setoptions, setSerie1,
setSerie2, setTotal, setEfectivo, setTarjeta, setAnulacion, setTicket, setTransac}) => {

  const formatterSoles = new Intl.NumberFormat('es-PE', { style: 'currency', currency: 'PEN' });

  const crearOption = (tienda) => {
    let option1;
    let lblTotal = '';
    let lblTotalPasado = '';
    switch (tiempo) {
      case 1:
        lblTotal = 'Total de ventas de hoy';
        lblTotalPasado = 'Ayer';
        break;
      case 2:
        lblTotal = 'Total de ventas de la semana';
        lblTotalPasado = 'Semana pasado';
        break;
      case 3:
        lblTotal = 'Total de ventas del mes';
        lblTotalPasado = 'Mes pasado';
      default:
        break;
    }

    getFacturas(tiempo, tienda).then(e => {
      let total = e.dataTotales.reduce((a, b) => {
        return a + b;
      }, 0);
      let totalPasado = e.dataTotalesAnt.reduce((a, b) => {
        return a + b;
      }, 0);      
      let porcentaje = "";

      if (total == 0 && totalPasado > 0) {
        porcentaje = "-100%";
      } else if(totalPasado == 0 && total >0){
        porcentaje = "+100%";
      } else if(total == 0 && totalPasado == 0){
        porcentaje = "+0%";
      } else if(total > 0 && totalPasado > 0){
        porcentaje = (100 * (total - totalPasado) / totalPasado).toFixed(2);
        porcentaje = porcentaje.toString().substring(0, 1) == '-' ? porcentaje.toString() + '%' :
        '+' + porcentaje.toString() + '%';
      }

      let lblAumento = '';
      if (total - totalPasado < 0) {
        lblAumento = 'Disminucion';
        setIcons('fa-solid fa-arrow-trend-down');
      } else {
        lblAumento = 'Aumento'
        setIcons('fa-solid fa-arrow-trend-up');
      }
      option1 = {
        chart: {
          height: 280,
          type: "area"
        },
        xaxis: {
          type: 'datetime',
          categories: e.categoria
        },
        fill: {
          type: ["gradient", "gradient"],
          gradient: { 
            shadeIntensity: 1,
            opacityFrom: [ 0.1, 0.7],
            opacityTo: [ 0.7, 0.9]
          } 
        },
        dataLabels: {
          enabled: false
        },
        stroke:
        {
            width: [0.5, 3]
        }
      };
      setoptions(option1);
      setSerie1(e.dataTotalesAnt);
      setSerie2(e.dataTotales);
      setTotal({
        total: formatterSoles.format(total),
        totalPasado: formatterSoles.format(totalPasado),
        label1: lblTotal,
        label2: lblTotalPasado,
        porcentaje: porcentaje,
        resta: lblAumento + ' : ' + formatterSoles.format(total - totalPasado)
      });
      // let porcEfectivo = 
      // (100 * (e.indicadores[0].totEfectivo - e.indicadores[0].totEfectivoAnt) / 
      // e.indicadores[0].totEfectivoAnt).toFixed(2) + '%';
      // let porcTarjeta = 
      // (100 * (e.indicadores[0].totTarjeta - e.indicadores[0].totTarjetaAnt) / 
      // e.indicadores[0].totTarjetaAnt).toFixed(2) + '%';


      let porcEfectivo = "0%";
      let porcTarjeta = "0%";
      let porcAnulacion = "0%";
      let porcTicket = "0%";
      let porcTransa = "0%";

      if (e.indicadores[0].totEfectivo == 0 && e.indicadores[0].totEfectivoAnt > 0) {
        porcEfectivo = "-100%";
      } else if(e.indicadores[0].totEfectivoAnt == 0 && e.indicadores[0].totEfectivo > 0){
        porcEfectivo = "100%";
      } else if(e.indicadores[0].totEfectivo == 0 && e.indicadores[0].totEfectivoAnt == 0){
        porcEfectivo = "0%";
      } else if(e.indicadores[0].totEfectivo > 0 && e.indicadores[0].totEfectivoAnt > 0){
        porcEfectivo = 
        (100 * (e.indicadores[0].totEfectivo - e.indicadores[0].totEfectivoAnt) / 
        e.indicadores[0].totEfectivoAnt).toFixed(2) + '%';
      }

      if (e.indicadores[0].totTarjeta == 0 && e.indicadores[0].totTarjetaAnt > 0) {
        porcTarjeta = "-100%";
      } else if(e.indicadores[0].totTarjetaAnt == 0 && e.indicadores[0].totTarjeta > 0){
        porcTarjeta = "100%";
      } else if(e.indicadores[0].totTarjeta == 0 && e.indicadores[0].totTarjetaAnt == 0){
        porcTarjeta = "0%";
      } else if(e.indicadores[0].totTarjeta > 0 && e.indicadores[0].totTarjetaAnt > 0){
        porcTarjeta = 
        (100 * (e.indicadores[0].totTarjeta - e.indicadores[0].totTarjetaAnt) / 
        e.indicadores[0].totTarjetaAnt).toFixed(2) + '%';
      }

      if (e.indicadores[0].cantAnulacion == 0 && e.indicadores[0].cantAnulacionAnt > 0) {
        porcAnulacion = "-100%";
      } else if(e.indicadores[0].cantAnulacionAnt == 0 && e.indicadores[0].cantAnulacion >0){
        porcAnulacion = "100%";
      } else if(e.indicadores[0].cantAnulacion == 0 && e.indicadores[0].cantAnulacionAnt == 0){
        porcAnulacion = "0%";
      } else if(e.indicadores[0].cantAnulacion > 0 && e.indicadores[0].cantAnulacionAnt > 0){
        porcAnulacion = 
        (100 * (e.indicadores[0].cantAnulacion - e.indicadores[0].cantAnulacionAnt) / 
        e.indicadores[0].cantAnulacionAnt).toFixed(2) + '%';
      }
      
      if (e.indicadores[0].tiketProm == 0 && e.indicadores[0].tiketPromAnt > 0) {
        porcTicket = "-100%";
      } else if(e.indicadores[0].tiketPromAnt == 0 && e.indicadores[0].tiketProm >0){
        porcTicket = "100%";
      } else if(e.indicadores[0].tiketProm == 0 && e.indicadores[0].tiketPromAnt == 0){
        porcTicket = "0%";
      } else if(e.indicadores[0].tiketProm > 0 && e.indicadores[0].tiketPromAnt > 0){
        porcTicket = 
        (100 * (e.indicadores[0].tiketProm - e.indicadores[0].tiketPromAnt) / 
        e.indicadores[0].tiketPromAnt).toFixed(2) + '%';
      }

      if (e.indicadores[0].cantTransac == 0 && e.indicadores[0].cantTransacAnt > 0) {
        porcTransa = "-100%";
      } else if(e.indicadores[0].cantTransacAnt == 0 && e.indicadores[0].cantTransac >0){
        porcTransa = "100%";
      } else if(e.indicadores[0].cantTransac == 0 && e.indicadores[0].cantTransacAnt == 0){
        porcTransa = "0%";
      } else if(e.indicadores[0].cantTransac > 0 && e.indicadores[0].cantTransacAnt > 0){
        porcTransa = 
        (100 * (e.indicadores[0].cantTransac - e.indicadores[0].cantTransacAnt) / 
        e.indicadores[0].cantTransacAnt).toFixed(2) + '%';
      }

      setEfectivo({
        label: lblTotalPasado, 
        total: e.indicadores[0].totEfectivo, 
        totalAyer: e.indicadores[0].totEfectivoAnt, 
        porcentaje: porcTarjeta,
        icon: porcEfectivo.substring(0, 1) == '-' ? 
        'fa-solid fa-arrow-trend-down fa-1x' : 
        'fa-solid fa-arrow-trend-up fa-1x'
      });
      setTarjeta({
        label: lblTotalPasado, 
        total: e.indicadores[0].totTarjeta, 
        totalAyer: e.indicadores[0].totTarjetaAnt, 
        porcentaje: porcEfectivo,
        icon: porcEfectivo.substring(0, 1) == '-' ? 
        'fa-solid fa-arrow-trend-down fa-1x' : 
        'fa-solid fa-arrow-trend-up fa-1x'
      })
      setAnulacion({
        label: lblTotalPasado, 
        total: e.indicadores[0].cantAnulacion, 
        totalAyer: e.indicadores[0].cantAnulacionAnt, 
        porcentaje: porcAnulacion,
        icon: porcAnulacion.substring(0, 1) == '-' ? 
        'fa-solid fa-arrow-trend-down fa-1x' : 
        'fa-solid fa-arrow-trend-up fa-1x'
      })
      setTicket({
        label: lblTotalPasado, 
        total: e.indicadores[0].tiketProm, 
        totalAyer: e.indicadores[0].tiketPromAnt, 
        porcentaje: porcTicket,
        icon: porcTicket.substring(0, 1) == '-' ? 
        'fa-solid fa-arrow-trend-down fa-1x' : 
        'fa-solid fa-arrow-trend-up fa-1x'
      });
      setTransac({
        label: 'Ayer', 
        total: e.indicadores[0].cantTransac, 
        totalAyer: e.indicadores[0].cantTransacAnt, 
        porcentaje: porcTransa,
        icon: porcTransa.substring(0, 1) == '-' ? 
        'fa-solid fa-arrow-trend-down fa-1x' : 
        'fa-solid fa-arrow-trend-up fa-1x'
      });
    })
  }
  const handleInputChange = (e) => {
    setTienda(e);
    crearOption(e);
  }

    let tiendas = [
        { label: "Todas las tiendas", value: "" },
        { label: "Chacarilla I", value: "01" },
        { label: "Miraflores", value: "02" },
    ]

    return (
    <div className="" id="selectTienda">
      <select className='form-select'
        name="cars" 
        id="cars" 
        onChange={ (e) => handleInputChange(e.target.value) }>
        { 
          tiendas.map(
            (tienda) => 
              <option key={tienda.label} value={tienda.value}>
                {tienda.label}
              </option>
          ) 
        }
      </select>
    </div>
  )
}

// 05:40