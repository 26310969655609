import React, { useEffect, useState } from 'react'
import { SelectArea } from './components/SelectArea'
import './index.css';
import './css/indexApp.css';
import { ChartComparativo } from './components/ChartComparativo';
import { Hoysemanames } from './components/Hoysemanames';
import { Totalventas } from './components/Totalventas';
import { getFacturas } from './helpers/getFacturas';
import { TotalEfectivo } from './components/TotalEfectivo';
import { TotalTarjeta } from './components/TotalTarjeta';
import { TotalAnulacion } from './components/TotalAnulacion';
import { Totalticket } from './components/TotalTicket';
import { TotalTransac } from './components/TotalTransac';
import { SelectTienda } from './components/SelectTienda';

export const IndexApp = () => {

  const formatterSoles = new Intl.NumberFormat('es-PE', { style: 'currency', currency: 'PEN' });

  const [tiempo, setTiempo] = useState(1);
  const [area, setArea] = useState("");
  const [tienda, setTienda] = useState('');
  const [icons, setIcons] = useState('fa-solid fa-arrow-trend-up');
  const [serie1, setSerie1] = useState([]);
  const [serie2, setSerie2] = useState([]);
  const [efectivo, setEfectivo] = useState(
    {label: 'Hoy', total: 0, totalAyer: 0, porcentaje: '0%', icono: 'fa-solid fa-arrow-trend-up'}
  );
  const [tarjeta, setTarjeta] = useState({
    label: 'Hoy', total: 0, totalAyer: 0, porcentaje: '0%', icono: 'fa-solid fa-arrow-trend-up'
  });
  const [anulacion, setAnulacion] = useState({
    label: 'Hoy', total: 0, totalAyer: 0, porcentaje: '0%', icono: 'fa-solid fa-arrow-trend-up'
  });
  const [ticket, setTicket] = useState({
    label: 'Hoy', total: 0, totalAyer: 0, porcentaje: '0%', icono: 'fa-solid fa-arrow-trend-up'
  });
  const [transac, setTransac] = useState({
    label: 'Hoy', total: 0, totalAyer: 0, porcentaje: '0%', icono: 'fa-solid fa-arrow-trend-up'
  });
  const [options, setoptions] = useState({
    chart: {
      height: 280,
      type: "area"
    },
    xaxis: {
      categories: []
    },
    fill: {
      type: ["gradient", "gradient"],
      gradient: {
        shadeIntensity: 1,
        opacityFrom: [0.1, 0.7],
        opacityTo: [0.7, 0.9]
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      width: [0.5, 3]
    }
  });

  useEffect(() => {
    let lblTotal = 'Total de ventas de hoy';
    let lblTotalPasado = 'Ayer';
    getFacturas(1, "").then(e => {
      let total = e.dataTotales.reduce((a, b) => {
        return a + b;
      }, 0);
      let totalPasado = e.dataTotalesAnt.reduce((a, b) => {
        return a + b;
      }, 0);      
      let porcentaje = "";

      if (total == 0 && totalPasado > 0) {
        porcentaje = "-100%";
      } else if(totalPasado == 0 && total >0){
        porcentaje = "+100%";
      } else if(total == 0 && totalPasado == 0){
        porcentaje = "+0%";
      } else if(total > 0 && totalPasado > 0){
        porcentaje = (100 * (total - totalPasado) / totalPasado).toFixed(2);
        porcentaje = porcentaje.toString().substring(0, 1) == '-' ? porcentaje.toString() + '%' :
        '+' + porcentaje.toString() + '%';
      }


      let lblAumento = '';
      if (total - totalPasado < 0) {
        lblAumento = 'Disminucion';
        setIcons('fa-solid fa-arrow-trend-down');
      } else {
        lblAumento = 'Aumento'
        setIcons('fa-solid fa-arrow-trend-up');
      }
      setoptions({
        chart: {
          height: 280,
          type: "area"
        },
        xaxis: {
          categories: e.categoria
        },
        fill: {
          type: ["gradient", "gradient"],
          gradient: {
            shadeIntensity: 1,
            opacityFrom: [0.1, 0.7],
            opacityTo: [0.7, 0.9]
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: [0.5, 3]
        }
      });
      setSerie1(e.dataTotalesAnt);
      setSerie2(e.dataTotales);
      setTotal({
        total: formatterSoles.format(total),
        totalPasado: formatterSoles.format(totalPasado),
        label1: lblTotal,
        label2: lblTotalPasado,
        porcentaje: porcentaje,
        resta: lblAumento + ' : ' + formatterSoles.format(total - totalPasado)
      });

      let porcEfectivo = "0%";
      let porcTarjeta = "0%";
      let porcAnulacion = "0%";
      let porcTicket = "0%";
      let porcTransa = "0%";
      
      if (e.indicadores[0].totEfectivo == 0 && e.indicadores[0].totEfectivoAnt > 0) {
        porcEfectivo = "-100%";
      } else if(e.indicadores[0].totEfectivoAnt == 0 && e.indicadores[0].totEfectivo > 0){
        porcEfectivo = "100%";
      } else if(e.indicadores[0].totEfectivo == 0 && e.indicadores[0].totEfectivoAnt == 0){
        porcEfectivo = "0%";
      } else if(e.indicadores[0].totEfectivo > 0 && e.indicadores[0].totEfectivoAnt > 0){
        porcEfectivo = 
        (100 * (e.indicadores[0].totEfectivo - e.indicadores[0].totEfectivoAnt) / 
        e.indicadores[0].totEfectivoAnt).toFixed(2) + '%';
      }

      if (e.indicadores[0].totTarjeta == 0 && e.indicadores[0].totTarjetaAnt > 0) {
        porcTarjeta = "-100%";
      } else if(e.indicadores[0].totTarjetaAnt == 0 && e.indicadores[0].totTarjeta > 0){
        porcTarjeta = "100%";
      } else if(e.indicadores[0].totTarjeta == 0 && e.indicadores[0].totTarjetaAnt == 0){
        porcTarjeta = "0%";
      } else if(e.indicadores[0].totTarjeta > 0 && e.indicadores[0].totTarjetaAnt > 0){
        porcTarjeta = 
        (100 * (e.indicadores[0].totTarjeta - e.indicadores[0].totTarjetaAnt) / 
        e.indicadores[0].totTarjetaAnt).toFixed(2) + '%';
      }

      if (e.indicadores[0].cantAnulacion == 0 && e.indicadores[0].cantAnulacionAnt > 0) {
        porcAnulacion = "-100%";
      } else if(e.indicadores[0].cantAnulacionAnt == 0 && e.indicadores[0].cantAnulacion >0){
        porcAnulacion = "100%";
      } else if(e.indicadores[0].cantAnulacion == 0 && e.indicadores[0].cantAnulacionAnt == 0){
        porcAnulacion = "0%";
      } else if(e.indicadores[0].cantAnulacion > 0 && e.indicadores[0].cantAnulacionAnt > 0){
        porcAnulacion = 
        (100 * (e.indicadores[0].cantAnulacion - e.indicadores[0].cantAnulacionAnt) / 
        e.indicadores[0].cantAnulacionAnt).toFixed(2) + '%';
      }

      if (e.indicadores[0].tiketProm == 0 && e.indicadores[0].tiketPromAnt > 0) {
        porcTicket = "-100%";
      } else if(e.indicadores[0].tiketPromAnt == 0 && e.indicadores[0].tiketProm >0){
        porcTicket = "100%";
      } else if(e.indicadores[0].tiketProm == 0 && e.indicadores[0].tiketPromAnt == 0){
        porcTicket = "0%";
      } else if(e.indicadores[0].tiketProm > 0 && e.indicadores[0].tiketPromAnt > 0){
        porcTicket = 
        (100 * (e.indicadores[0].tiketProm - e.indicadores[0].tiketPromAnt) / 
        e.indicadores[0].tiketPromAnt).toFixed(2) + '%';
      }
      
      if (e.indicadores[0].cantTransac == 0 && e.indicadores[0].cantTransacAnt > 0) {
        porcTransa = "-100%";
      } else if(e.indicadores[0].cantTransacAnt == 0 && e.indicadores[0].cantTransac >0){
        porcTransa = "100%";
      } else if(e.indicadores[0].cantTransac == 0 && e.indicadores[0].cantTransacAnt == 0){
        porcTransa = "0%";
      } else if(e.indicadores[0].cantTransac > 0 && e.indicadores[0].cantTransacAnt > 0){
        porcTransa = 
        (100 * (e.indicadores[0].cantTransac - e.indicadores[0].cantTransacAnt) / 
        e.indicadores[0].cantTransacAnt).toFixed(2) + '%';
      }

      setEfectivo({
        label: 'Ayer', 
        total: e.indicadores[0].totEfectivo, 
        totalAyer: e.indicadores[0].totEfectivoAnt, 
        porcentaje: porcEfectivo,
        icon: porcEfectivo.substring(0, 1) == '-' ? 
        'fa-solid fa-arrow-trend-down fa-1x' : 
        'fa-solid fa-arrow-trend-up fa-1x'
      });
      setTarjeta({
        label: 'Ayer', 
        total: e.indicadores[0].totTarjeta, 
        totalAyer: e.indicadores[0].totTarjetaAnt, 
        porcentaje: porcTarjeta,
        icon: porcTarjeta.substring(0, 1) == '-' ? 
        'fa-solid fa-arrow-trend-down fa-1x' : 
        'fa-solid fa-arrow-trend-up fa-1x'
      });
      setAnulacion({
        label: 'Ayer', 
        total: e.indicadores[0].cantAnulacion, 
        totalAyer: e.indicadores[0].cantAnulacionAnt, 
        porcentaje: porcAnulacion,
        icon: porcAnulacion.substring(0, 1) == '-' ? 
        'fa-solid fa-arrow-trend-down fa-1x' : 
        'fa-solid fa-arrow-trend-up fa-1x'
      });
      setTicket({
        label: 'Ayer', 
        total: e.indicadores[0].tiketProm, 
        totalAyer: e.indicadores[0].tiketPromAnt, 
        porcentaje: porcTicket,
        icon: porcTicket.substring(0, 1) == '-' ? 
        'fa-solid fa-arrow-trend-down fa-1x' : 
        'fa-solid fa-arrow-trend-up fa-1x'
      });
      setTransac({
        label: 'Ayer', 
        total: e.indicadores[0].cantTransac, 
        totalAyer: e.indicadores[0].cantTransacAnt, 
        porcentaje: porcTransa,
        icon: porcTransa.substring(0, 1) == '-' ? 
        'fa-solid fa-arrow-trend-down fa-1x' : 
        'fa-solid fa-arrow-trend-up fa-1x'
      });
    });
  }, [])
  
  const [total, setTotal] = useState({
    total: "19,224.34",
    totalPasado: "18,500.14",
    label1: 'Total de ventas de hoy',
    label2: 'ayer',
    porcentaje: '+ 5 %',
    resta: 'Aumento: S/ 724.20'
  });
  return (
    <div className='px-4'>
      <div className='row'>
        <div className='col'>
          <SelectTienda setTienda={setTienda} tiempo={tiempo} setIcons={setIcons}
          setoptions={setoptions} setSerie1={setSerie1} setSerie2={setSerie2} 
          setTotal={setTotal} setEfectivo={setEfectivo} setTarjeta={setTarjeta}
          setAnulacion={setAnulacion} setTicket={setTicket} setTransac={setTransac}/>
        </div>
        {/* <div className='col'>
          <SelectArea setArea={setArea} />
        </div> */}
      </div>
      <div className='row justify-content-between pb-2'>
        <div className='col'>
          <div className='p-2' id='estadisticalbl'>
            Estadisticas
          </div>
        </div>
        <div className='col'>
          <div className='d-flex flex-row-reverse text-center'>
            <Hoysemanames setoptions={setoptions} setTotal={setTotal}
              setSerie1={setSerie1} setSerie2={setSerie2} area={area} setIcons={setIcons} 
              setEfectivo={setEfectivo} setTarjeta={setTarjeta} setAnulacion={setAnulacion} 
              setTicket={setTicket} setTransac={setTransac} setTiempo={setTiempo} 
              tienda={tienda} />
          </div>
        </div>
      </div>
      <div className='card'>
        <div className='row g-0'>
          <div className='col-md-5'>
            <div className='card-body pt-4'>
              <Totalventas total={total} icons={icons} />
            </div>
          </div>
          <ChartComparativo options={options} serie1={serie1} serie2={serie2} />
        </div>
      </div>
      <div className="row row-cols-1 row-cols-md-2 g-5">
        <TotalEfectivo efectivo={efectivo} />
        <TotalTarjeta tarjeta={tarjeta} />
        <TotalAnulacion anulacion={anulacion} />
        <Totalticket ticket={ticket} />
        <TotalTransac transac={transac} />
      </div>
    </div>
  )
}