import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const TotalTransac = ({ transac }) => {

    // const formatterSoles = new Intl.NumberFormat('es-PE', { style: 'currency', currency: 'PEN' });

    var porcentajeVal = transac.porcentaje.substring(0, 1);
    var elem = document.getElementById('idPorcentajetransac');
    var elem1 = document.getElementById('iconPorctransac');
    if (elem != null) {
        if (porcentajeVal == '-') {
            elem.style.color = '#ff4d4d';
            elem1.style.backgroundColor = '#ff4d4d';
        } else {
            elem.style.color = '#58C347';
            elem1.style.backgroundColor = '#58C347';
        }
    }

    return (
        <>
        
        <div className="col">
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-auto">
                                <FontAwesomeIcon icon={ 'fa-solid fa-hashtag' } 
                                size='2x' color='#9DABF1'/>
                        </div>
                        <div className="col valortotalpasado2 d-flex align-items-center">
                            Transacciones realizadas
                        </div>
                    </div>
                    <div className="totalIndicador">
                        { transac.total }
                    </div>
                    <div className="row">
                        <div className="col-auto">
                            <div className="valortotalpasado">{ transac.label }</div>
                            <div className="valortotalpasado2">
                                { transac.totalAyer }
                            </div>
                        </div>
                        <div className="col align-items-center">
                            <div className="row">
                                <div id="idPorcentajetransac" 
                                className="fw-bolder fs-4 d-flex justify-content-end col">
                                    { transac.porcentaje }
                                </div>
                                <div className="col-auto rounded d-flex align-items-center"
                                id='iconPorctransac'>
                                    <FontAwesomeIcon icon={ transac.icon } 
                                    color='white'/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        </>
    )
}