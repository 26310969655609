export const getFacturas = async(parametro, tienda) => {

    const totales = [];

    console.log(parametro);

    const url = 
    `https://prueba.smartdesignperu.com/api/factura/param/
    ${ encodeURI(parametro)}/${ encodeURI(tienda)}`
    const resp = await fetch(url, {mode:'cors'});
    const factura = await resp.json();
    return factura;
}